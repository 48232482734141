import "../styles/Welcome.css"
function Welcome(){
    return(
        <section id="welcome" >
            <div className="text-container" >
                <h2 id="welcome-text">Welcome to My Portfolio</h2>
                <h3>Matt Draghi</h3>
            </div>
        </section>
    )
}

export default Welcome